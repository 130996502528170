<template>
  <div class="teams-setting">
    <div class="header">
      <div class="titre">
        <h3>Gestion des équipes</h3>
      </div>
      <div class="button">
        <b-button
          size="sm"
          variant="light"
          v-if="sortDesc"
          class="searchIcon padd-btn"
          @click="sortBy"
        >
          <font-awesome-icon icon="caret-up" />
        </b-button>
        <b-button
          size="sm"
          variant="light"
          v-else
          class="searchIcon padd-btn"
          @click="sortBy"
        >
          <font-awesome-icon icon="caret-down" />
        </b-button>
        <b-button
          size="sm"
          variant="success"
          v-b-modal.addTeamsModal
          :disabled="initLoading"
          class="searchIcon"
        >
          <font-awesome-icon class icon="plus" /> Ajouter une équipe
        </b-button>
      </div>
    </div>
    <div class="input-search-teams">
      <div class="block-search">
        <span class="searchIcon" title="search" @click="filter">
          <font-awesome-icon icon="search"
        /></span>
        <input
          :disabled="initLoading"
          class="search"
          type="text"
          placeholder="Rechercher une équipe"
          v-debounce:400="filter"
          v-model="full_name"
        />
      </div>
      <div
        v-if="getTeamsLoading || initLoading"
        class="init-loading three-dots-loading mt-2"
      >
        Chargement en cours
      </div>
    </div>
    <hr />
    <b-list-group>
      <b-list-group-item
        href="#"
        :class="Equipe.visible ? '' : 'collapsed'"
        :aria-controls="'collapse-' + index"
        @click="Equipe.visible = !Equipe.visible"
        v-for="(Equipe, index) in teams"
        :key="index"
      >
        <div class="content">
          <div class="gras">
            <div class="name">{{ Equipe.name }}</div>
            <div class="type" v-if="Equipe.type == 'regie'">
              Régie
            </div>
            <div class="type" v-if="Equipe.type == 'filiale'">
              Filiale
            </div>
            <div class="type" v-if="Equipe.type == 'ge'">
              Certigaia
            </div>
            <div class="type" v-if="Equipe.type == 'installateur'">
              Installateur
            </div>
          </div>
          <div class="right">
            <div class="actions">
              <b-button
                size="sm"
                variant="primary"
                title="Modifier"
                @click.prevent.stop="handleUpdateClick(Equipe)"
              >
                <font-awesome-icon class icon="pencil-alt" />
              </b-button>

              <b-button
                size="sm"
                title="Supprimer"
                variant="danger"
                @click.prevent.stop="handleDeleteClick(Equipe)"
              >
                <font-awesome-icon class icon="trash-alt" />
              </b-button>
            </div>
            <div class="icon">
              <font-awesome-icon class="icon1" icon="angle-up" />
              <font-awesome-icon class="icon1" icon="angle-down" />
            </div>
          </div>
        </div>
        <b-collapse
          :id="'collapse-' + index"
          v-model="Equipe.visible"
          class="mt-2"
        >
          <b-card>
            <b-row>
              <b-col>
                <div>
                  <strong>Chef d'équipe</strong>
                  <p>
                    {{ Equipe.manager.data.first_name }}
                    {{ Equipe.manager.data.last_name }}
                  </p>
                </div>
                <div>
                  <strong>Membres de l'équipe</strong>
                  <div
                    class="content-members"
                    v-if="
                      Equipe.members &&
                        Equipe.members.data &&
                        Equipe.members.data.length
                    "
                  >
                    <ul
                      v-for="item in Equipe.members.data"
                      :key="item.id"
                      :value="item.id"
                    >
                      <li>
                        <div
                          :class="{
                            'icon-exclamation': item.pipe_tech_deleted === 1
                          }"
                          :title="
                            item.pipe_tech_deleted === 1
                              ? 'Technicien supprimé de pipedrive'
                              : ''
                          "
                        >
                          {{ item.first_name }} {{ item.last_name }}
                        </div>
                        <div>
                          <strong class="type">
                            ({{ item.type | typeFormat }})
                          </strong>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div v-else class="message">
                    Aucun Membres
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-collapse>
      </b-list-group-item>
      <b-list-group-item
        class="aucun-equipe"
        v-if="(!teams || !teams.length) && !initLoading"
      >
        Aucun équipe</b-list-group-item
      >
    </b-list-group>

    <!-- Add Team Modal -->
    <b-modal
      no-close-on-backdrop
      id="addTeamsModal"
      title="Ajouter une équipe"
      ref="addTeamsModal"
      :hide-footer="true"
      @show="resetModal"
      @hidden="resetModal"
    >
      <form @submit.prevent="handleSubmit">
        <b-row class="row">
          <b-col class="col1">
            <!-- NOM TEAM -->
            <b-form-group
              label="Nom de l'équipe * "
              label-for="Nom_equipe-input"
            >
              <b-form-input
                id="Nom_equipe-input"
                v-model="teamToAdd.name"
                required
              ></b-form-input>
            </b-form-group>
            <!-- TYPES -->
            <b-form-group label="Type * ">
              <Multiselect
                v-model="teamToAdd.type"
                @input="changeType($event)"
                label="name"
                track-by="id"
                :showLabels="false"
                :closeOnSelect="true"
                open-direction="bottom"
                :allow-empty="false"
                :options="teamsTypes || []"
                :searchable="true"
                :internal-search="true"
                :max-height="600"
                placeholder="Rechercher type"
                required
              >
                <span slot="noResult">Aucun type trouvé.</span>
                <span slot="noOptions">Aucun type trouvé.</span>
              </Multiselect>
            </b-form-group>
            <!-- TEAMS TYPE FILIALE AND Certigaia -->
            <template v-if="type == 'filiale' || type == 'ge'">
              <b-form-group label="Chef d'équipe * ">
                <Multiselect
                  v-model="teamToAdd.manager_id"
                  label="name"
                  track-by="id"
                  :showLabels="false"
                  :closeOnSelect="true"
                  open-direction="bottom"
                  :allow-empty="true"
                  :options="adminsGlobal"
                  :searchable="true"
                  :internal-search="true"
                  :max-height="600"
                  placeholder="Rechercher chef d'équipe"
                  required
                >
                  <span slot="noResult">Aucun chef d'équipe trouvé.</span>
                  <span slot="noOptions">Aucun chef d'équipe trouvé.</span>
                </Multiselect>
              </b-form-group>
              <!-- CATEGORY -->
              <b-form-group label="Sous-catégories">
                <Multiselect
                  v-model="teamToAdd.subcategories"
                  label="name"
                  track-by="id"
                  :showLabels="false"
                  :closeOnSelect="true"
                  open-direction="bottom"
                  :allow-empty="true"
                  :options="getSubCategory || []"
                  :searchable="true"
                  :internal-search="true"
                  :max-height="600"
                  placeholder="Rechercher Sous-catégories"
                  :multiple="true"
                  required
                >
                  <span slot="noResult">Aucun option trouvé.</span>
                  <span slot="noOptions">Aucun option trouvé.</span>
                </Multiselect>
              </b-form-group>
              <!-- LOGO FILIALE -->
              <b-form-group
                label="Logo Filiale"
                label-for="file"
                v-if="type == 'filiale'"
              >
                <b-form-file
                  v-model="teamToAdd.image"
                  placeholder=""
                  drop-placeholder=""
                  @change="previewImage"
                  accept="image/*"
                ></b-form-file>
                <strong class="type">Taille image 286 x 99 pixels </strong>
              </b-form-group>
              <!-- Prévisionnel Stock -->
              <div class="check-switch mrgswitch">
                <b-form-checkbox
                  class="switch"
                  v-model="teamToAdd.stock"
                  :value="1"
                  :unchecked-value="0"
                  switch
                >
                </b-form-checkbox>
                Stock
              </div>
            </template>

            <!-- TEAMS TYPE REGIE -->
            <div v-if="type == 'regie' || type == 'installateur'">
              <!-- CHEF D'EQUIPE TYPE REGIE -->
              <b-form-group label="Chef d'équipe * ">
                <Multiselect
                  v-model="teamToAdd.manager_id_regie"
                  label="name"
                  track-by="id"
                  :showLabels="false"
                  :closeOnSelect="true"
                  open-direction="bottom"
                  :allow-empty="true"
                  :options="admins"
                  :searchable="true"
                  :internal-search="true"
                  :max-height="600"
                  placeholder="Rechercher chef d'équipe"
                  required
                >
                  <span slot="noResult">Aucun chef d'équipe trouvé.</span>
                  <span slot="noOptions">Aucun chef d'équipe trouvé.</span>
                </Multiselect>
              </b-form-group>
              <!-- CATEGORY -->
              <b-form-group label="Sous-catégories">
                <Multiselect
                  v-model="teamToAdd.subcategories"
                  label="name"
                  track-by="id"
                  :showLabels="false"
                  :closeOnSelect="true"
                  open-direction="bottom"
                  :allow-empty="true"
                  :options="getSubCategory || []"
                  :searchable="true"
                  :internal-search="true"
                  :max-height="600"
                  placeholder="Rechercher Sous-catégories"
                  :multiple="true"
                  required
                >
                  <span slot="noResult">Aucun option trouvé.</span>
                  <span slot="noOptions">Aucun option trouvé.</span>
                </Multiselect>
              </b-form-group>
              <!-- Prévisionnel Stock -->
              <div class="check-switch mrgswitch">
                <b-form-checkbox
                  class="switch"
                  v-model="teamToAdd.stock"
                  :value="1"
                  :unchecked-value="0"
                  switch
                >
                </b-form-checkbox>
                Stock
              </div>
              <!-- FORMULAIRE RAPIDE -->
              <div class="check-switch mrgswitch">
                <b-form-checkbox
                  class="switch"
                  v-model="teamToAdd.see_form_fast"
                  :value="1"
                  :unchecked-value="0"
                  switch
                >
                </b-form-checkbox>
                Formulaire Rapide
              </div>
              <!-- Accès total aux documents   -->
              <div class="check-switch mrgswitch" v-if="type == 'regie'">
                <b-form-checkbox
                  class="switch"
                  v-model="teamToAdd.acces_documents"
                  :value="1"
                  :unchecked-value="0"
                  switch
                >
                </b-form-checkbox>
                Accès total aux documents
              </div>
              <!-- SOURCE -->
              <b-form-group label="Source * ">
                <Multiselect
                  v-model="teamToAdd.source_id"
                  @select="selectedSource($event)"
                  label="name_internal"
                  track-by="id"
                  :showLabels="false"
                  :closeOnSelect="true"
                  open-direction="bottom"
                  :allow-empty="true"
                  :options="computedRegies"
                  :searchable="true"
                  :internal-search="true"
                  :max-height="600"
                  placeholder="Rechercher source"
                  required
                >
                  <span slot="noResult">Aucun source trouvé.</span>
                  <span slot="noOptions">Aucun source trouvé.</span>
                </Multiselect>
              </b-form-group>
              <!-- AFFECTER LEAD -->
              <div>Affecter Lead selon:</div>
              <div class="check-switch">
                source
                <b-form-checkbox
                  class="switch ml-2"
                  v-model="teamToAdd.set_lead"
                  :value="1"
                  :unchecked-value="0"
                  switch
                >
                  département
                </b-form-checkbox>
              </div>
              <!-- COMMERCIAL SEDENTAIRE -->
              <b-form-group
                v-if="!teamToAdd.set_lead"
                label="Commercial sédentaire"
              >
                <b-form-select v-model="teamToAdd.comm_sed_id" disabled>
                  <option
                    v-for="item in getProjectsUsersCommercialsSedentaires"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.full_name }}</option
                  >
                </b-form-select>
              </b-form-group>
              <!-- LISTE DES DES TYPES DES PROJETS -->
              <b-form-group label="Liste des types des projets *">
                <template v-for="(type, index) in computedProjectTypes">
                  <b-form-checkbox
                    v-if="teamToAdd && teamToAdd.projectTypes[index]"
                    :key="'type-check-' + type.id"
                    :value="type.id"
                    v-model="teamToAdd.projectTypes[index].dtype_id"
                  >
                    <div>
                      {{ type.name }}
                    </div>
                    <div class="pipe-principal">
                      Pipeline principal :
                      <span class="color">
                        {{
                          type.pipeline_name
                            ? type.pipeline_name
                            : 'Aucun pipeline principal'
                        }}</span
                      >
                    </div>
                  </b-form-checkbox>
                  <b-form-group
                    v-if="
                      teamToAdd.projectTypes[index] &&
                        teamToAdd.projectTypes[index].dtype_id
                    "
                    :key="'type-select1-' + type.id"
                    label="Stade : "
                    class="stade-sous-stade-flex"
                  >
                    <span class="project-type-sub-tree"></span>
                    <Multiselect
                      v-model="teamToAdd.projectTypes[index].cstage_id"
                      @select="handleStageChange($event, type, index)"
                      label="name"
                      track-by="id"
                      :showLabels="false"
                      :closeOnSelect="true"
                      open-direction="bottom"
                      :allow-empty="true"
                      :options="type.stages"
                      :searchable="true"
                      :internal-search="true"
                      :max-height="600"
                      placeholder="Rechercher stade"
                      required
                    >
                      <span slot="noResult">Aucun stade trouvé.</span>
                      <span slot="noOptions">Aucun stade trouvé.</span>
                    </Multiselect>
                  </b-form-group>
                  <b-form-group
                    v-if="
                      teamToAdd.projectTypes[index] &&
                        teamToAdd.projectTypes[index].dtype_id &&
                        teamToAdd.projectTypes[index].cstage_id &&
                        teamToAdd.projectTypes[index].subStages
                    "
                    :key="'type-select2-' + type.id"
                    label="Sous-stade : "
                    class="stade-sous-stade-flex"
                  >
                    <span class="project-type-sub-tree child"></span>
                    <Multiselect
                      v-model="teamToAdd.projectTypes[index].csubstage_id"
                      label="name"
                      track-by="id"
                      :showLabels="false"
                      :closeOnSelect="true"
                      open-direction="bottom"
                      :allow-empty="true"
                      :options="teamToAdd.projectTypes[index].subStages"
                      :searchable="true"
                      :internal-search="true"
                      :max-height="600"
                      placeholder="Rechercher sous-stade"
                      required
                    >
                      <span slot="noResult">Aucun sous-stade trouvé.</span>
                      <span slot="noOptions">Aucun sous-stade trouvé.</span>
                    </Multiselect>
                  </b-form-group>
                </template>
              </b-form-group>
              <!-- LES 3 COMMISSIONS-->
              <b-form-group
                label="Commission 101 *"
                label-for="commission101-input"
              >
                <b-form-input
                  type="number"
                  id="commission101-input"
                  v-model="teamToAdd.coeff_101"
                  :required="type == 'regie'"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Commission 102 *"
                label-for="commission102-input"
              >
                <b-form-input
                  type="number"
                  id="commission102-input"
                  v-model="teamToAdd.coeff_102"
                  :required="type == 'regie'"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Commission 103 *"
                label-for="commission103-input"
              >
                <b-form-input
                  type="number"
                  id="commission103-input"
                  v-model="teamToAdd.coeff_103"
                  :required="type == 'regie'"
                ></b-form-input>
              </b-form-group>
            </div>
          </b-col>
          <b-col class="col2">
            <b-form-group
              label=" Membres de l'équipe "
              v-if="type == 'ge'"
            ></b-form-group>
            <div
              :class="{
                defilement: type != 'regie' || type != 'installateur',
                'defilement-regie': type == 'regie' || type == 'installateur'
              }"
            >
              <b-form-group v-if="type == 'regie' || type == 'installateur'">
                <b-form-group
                  label="Liste admins secondaires"
                  label-for="admin-secondaire"
                >
                </b-form-group>
                <div
                  v-if="
                    listsAdminsRegieComputed && listsAdminsRegieComputed.length
                  "
                >
                  <b-form-checkbox
                    v-for="(item, index) in listsAdminsRegieComputed"
                    :key="index"
                    v-model="teamToAdd.selected[item.id]"
                  >
                    <div>{{ item.first_name }} {{ item.last_name }}</div>
                    <div>
                      <strong class="type">
                        ({{ item.type | typeFormat }})
                      </strong>
                    </div>
                  </b-form-checkbox>
                </div>
                <div v-else class="aucunMembres">Aucun Admin</div>
                <b-form-group
                  v-if="type == 'regie' || type == 'installateur'"
                  label=" Membres de l'équipe "
                  label-for="membres-equipe"
                  class="titre"
                ></b-form-group>
                <div v-if="membersRegieComputed && membersRegieComputed.length">
                  <b-form-checkbox
                    v-for="(item, index) in membersRegieComputed"
                    :key="index"
                    v-model="teamToAdd.selected[item.id]"
                  >
                    <div>{{ item.first_name }} {{ item.last_name }}</div>
                    <div>
                      <strong class="type">
                        ({{ item.type | typeFormat }})
                      </strong>
                    </div>
                  </b-form-checkbox>
                </div>
                <div v-else class="aucunMembres">Aucun Membres</div>
              </b-form-group>

              <b-form-group v-if="type == 'filiale'">
                <b-form-group
                  label="Liste admins secondaires"
                  label-for="admin-secondaire"
                >
                </b-form-group>
                <div v-if="listsAdminsComputed && listsAdminsComputed.length">
                  <b-form-checkbox
                    v-for="(item, index) in listsAdminsComputed"
                    :key="index"
                    v-model="teamToAdd.selectedTechnicians[item.id]"
                  >
                    <div>{{ item.first_name }} {{ item.last_name }}</div>
                    <div>
                      <strong class="type">
                        ({{ item.type | typeFormat }})
                      </strong>
                    </div>
                  </b-form-checkbox>
                </div>
                <div v-else class="aucunMembres">Aucun Admin</div>
                <b-form-group
                  v-if="type == 'filiale'"
                  label=" Membres de l'équipe "
                  label-for="membres-equipe"
                  class="titre"
                ></b-form-group>
                <div v-if="membersComputed && membersComputed.length">
                  <b-form-checkbox
                    v-for="(item, index) in membersComputed"
                    :key="index"
                    v-model="teamToAdd.selectedTechnicians[item.id]"
                  >
                    <div
                      :class="{
                        'icon-exclamation': item.pipe_tech_deleted === 1
                      }"
                      :title="
                        item.pipe_tech_deleted === 1
                          ? 'Technicien supprimé de pipedrive'
                          : ''
                      "
                    >
                      {{ item.first_name }} {{ item.last_name }}
                    </div>
                    <div>
                      <strong class="type"
                        >({{ item.type | typeFormat }})
                      </strong>
                    </div>
                  </b-form-checkbox>
                </div>
                <div v-else class="aucunMembres">Aucun Membres</div>
              </b-form-group>

              <b-form-group v-if="type == 'ge'">
                <div v-if="membresGe && membresGe.length">
                  <b-form-checkbox
                    v-for="(item, index) in membresGe"
                    :key="index"
                    v-model="teamToAdd.selectedGe[index]"
                  >
                    <div>{{ item.first_name }} {{ item.last_name }}</div>
                    <div>
                      <strong class="type"
                        >({{ item.type | typeFormat }})</strong
                      >
                    </div>
                  </b-form-checkbox>
                </div>
                <div v-else class="aucunMembres">Aucun Membres</div>
              </b-form-group>
            </div>
          </b-col>
        </b-row>
        <!-- Add Team Modal: error message -->
        <div class="message">
          <div v-if="loading" class="three-dots-loading">
            Chargement en cours
          </div>
          <div v-if="error" class="error">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div>
        </div>
        <!-- Add Team Modal: buttons -->
        <div class="form-actions">
          <b-button
            @click="hideModal('addTeamsModal')"
            variant="outline-secondary"
            >Annuler</b-button
          >
          <b-button variant="success" type="submit">Enregistrer</b-button>
        </div>
      </form>
    </b-modal>
    <!-- END Add Team Modal -->

    <!-- Update Team Modal -->
    <b-modal
      no-close-on-backdrop
      ref="ModalUpdateTeam"
      id="ModalUpdateTeam"
      title="Editer l'équipe"
      :hide-footer="true"
      @hidden="resetModal"
    >
      <form v-if="selectTeam" @submit.prevent="handleSubmitUpdate">
        <b-row class="row">
          <b-col class="col1">
            <!-- NOM TEAM -->
            <b-form-group
              label="Nom de l'équipe * "
              label-for="Nom_equipe-input"
            >
              <b-form-input
                id="Nom_equipe-input"
                v-model="selectTeam.name"
                required
              ></b-form-input>
            </b-form-group>
            <!-- TYPES -->
            <b-form-group label="Type * " label-for="type-input">
              <b-form-select
                id="type-input"
                v-model="selectTeam.type"
                required
                disabled
              >
                <option
                  v-for="(item, index) in teamsTypes"
                  :key="index"
                  :value="item.id"
                  >{{ item.name }}</option
                >
              </b-form-select>
            </b-form-group>
            <!-- EQUIPE TYPE FILIALE -->
            <div>
              <template
                v-if="selectTeam.type == 'filiale' || selectTeam.type == 'ge'"
              >
                <!-- CHEF D'EQUIPE TYPE FILIALE -->
                <b-form-group label="Chef d'équipe * ">
                  <Multiselect
                    v-model="managerselected"
                    label="name"
                    track-by="id"
                    :options="adminsComputed"
                    :searchable="true"
                    :internal-search="true"
                    :showLabels="false"
                    :closeOnSelect="true"
                    open-direction="bottom"
                    :allow-empty="true"
                    :max-height="600"
                    placeholder="Rechercher chef d'équipe"
                    required
                  >
                    <span slot="noResult">Aucun chef d'équipe trouvé.</span>
                    <span slot="noOptions">Aucun chef d'équipe trouvé.</span>
                  </Multiselect>
                </b-form-group>
                <!-- CATEGORY -->
                <b-form-group label="Sous-catégories">
                  <Multiselect
                    v-model="selectedSubcategories"
                    label="name"
                    track-by="id"
                    :showLabels="false"
                    :closeOnSelect="true"
                    open-direction="bottom"
                    :allow-empty="true"
                    :options="getSubCategory || []"
                    :searchable="true"
                    :internal-search="true"
                    :max-height="600"
                    placeholder="Rechercher Sous-catégories"
                    :multiple="true"
                    required
                  >
                    <span slot="noResult">Aucun option trouvé.</span>
                    <span slot="noOptions">Aucun option trouvé.</span>
                  </Multiselect>
                </b-form-group>
                <!-- LOGO FILIALE -->
                <b-form-group
                  label="Logo Filiale"
                  label-for="file"
                  v-if="selectTeam.type == 'filiale'"
                >
                  <b-form-file
                    v-model="selectTeam.imageDataUpdate"
                    placeholder=""
                    drop-placeholder=""
                    @change="previewImageUpdate"
                    accept="image/*"
                  ></b-form-file>
                  <strong class="type">Taille image 268 x 99 pixels </strong>
                  <div class="mt-3" v-if="selectTeam.image">
                    <label> Logo </label>
                    <img class="image-preview" :src="selectTeam.image" />
                  </div>
                </b-form-group>
                <!-- Prévisionnel Stock -->
                <div class="check-switch mrgswitch">
                  <b-form-checkbox
                    class="switch"
                    v-model="selectTeam.stock"
                    :value="1"
                    :unchecked-value="0"
                    switch
                  >
                  </b-form-checkbox>
                  Stock
                </div>
              </template>
            </div>
            <!-- EQUIPE TYPE REGIE -->
            <div
              v-if="
                selectTeam.type == 'regie' || selectTeam.type == 'installateur'
              "
            >
              <!-- CHEF D'EQUIPE TYPE REGIE -->
              <b-form-group label="Chef d'équipe * ">
                <Multiselect
                  v-model="managerselectedRegie"
                  :options="computedadminUpdate"
                  label="full_name"
                  track-by="id"
                  :showLabels="false"
                  :closeOnSelect="true"
                  open-direction="bottom"
                  :allow-empty="true"
                  :searchable="true"
                  :internal-search="true"
                  :max-height="600"
                  placeholder="Rechercher chef d'équipe"
                  required
                >
                  <span slot="noResult">Aucun chef d'équipe trouvé.</span>
                  <span slot="noOptions">Aucun chef d'équipe trouvé.</span>
                </Multiselect>
              </b-form-group>
              <!-- CATEGORY -->
              <b-form-group label="Sous-catégories">
                <Multiselect
                  v-model="selectedSubcategories"
                  label="name"
                  track-by="id"
                  :showLabels="false"
                  :closeOnSelect="true"
                  open-direction="bottom"
                  :allow-empty="true"
                  :options="getSubCategory || []"
                  :searchable="true"
                  :internal-search="true"
                  :max-height="600"
                  placeholder="Rechercher Sous-catégories"
                  :multiple="true"
                  required
                >
                  <span slot="noResult">Aucun option trouvé.</span>
                  <span slot="noOptions">Aucun option trouvé.</span>
                </Multiselect>
              </b-form-group>
              <!-- Prévisionnel Stock -->
              <div class="check-switch mrgswitch">
                <b-form-checkbox
                  class="switch"
                  v-model="selectTeam.stock"
                  :value="1"
                  :unchecked-value="0"
                  switch
                >
                </b-form-checkbox>
                Stock
              </div>
              <!-- FORMULAIRE RAPIDE -->
              <div class="check-switch mrgswitch">
                <b-form-checkbox
                  class="switch"
                  v-model="selectTeam.see_form_fast"
                  :value="1"
                  :unchecked-value="0"
                  switch
                >
                </b-form-checkbox>
                Formulaire Rapide
              </div>
              <!-- Accès total aux documents   -->
              <div
                class="check-switch mrgswitch"
                v-if="selectTeam.type == 'regie'"
              >
                <b-form-checkbox
                  class="switch"
                  v-model="selectTeam.acces_documents"
                  :value="1"
                  :unchecked-value="0"
                  switch
                >
                </b-form-checkbox>
                Accès total aux documents
              </div>
              <!-- SOURCE -->
              <b-form-group label="Source * " label-for="regie-input">
                <Multiselect
                  v-model="selectTeam.source_id"
                  @select="selectedSourceUpdate($event)"
                  label="name_internal"
                  track-by="id"
                  :showLabels="false"
                  :closeOnSelect="true"
                  open-direction="bottom"
                  :allow-empty="true"
                  :options="regieUpdate"
                  :searchable="true"
                  :internal-search="true"
                  :max-height="600"
                  placeholder="Rechercher source"
                  :loading="getTeamsLoading"
                  required
                >
                  <span slot="noResult">Aucun source trouvé.</span>
                  <span slot="noOptions">Aucun source trouvé.</span>
                </Multiselect>
              </b-form-group>
              <!-- AFFECTER LEAD -->
              <div>
                Affecter Lead selon:
              </div>
              <div class="check-switch">
                source
                <b-form-checkbox
                  class="switch ml-2"
                  v-model="selectTeam.set_lead"
                  :value="1"
                  :unchecked-value="0"
                  switch
                >
                </b-form-checkbox>
                département
              </div>
              <!-- COMMERCIAL SEDENTAIRE -->
              <b-form-group
                v-if="!selectTeam.set_lead"
                label="Commercial sédentaire"
              >
                <b-form-select v-model="selectTeam.comm_sed_id" disabled>
                  <option
                    v-for="item in getProjectsUsersCommercialsSedentaires"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.full_name }}</option
                  >
                </b-form-select>
              </b-form-group>
              <!-- LISTE DES TYPES DES PROJETS -->
              <b-form-group label="Liste des types des projets *">
                <template v-for="(type, index) in computedProjectTypes">
                  <b-form-checkbox
                    v-if="selectTeamProjectTypes[index]"
                    :key="'type-check-u-' + type.id"
                    :value="type.id"
                    v-model="selectTeamProjectTypes[index].dtype_id"
                  >
                    <div>
                      {{ type.name }}
                    </div>
                    <div class="pipe-principal">
                      Pipeline principal :
                      <span class="color">
                        {{
                          type.pipeline_name
                            ? type.pipeline_name
                            : 'Aucun pipeline principal'
                        }}</span
                      >
                    </div>
                  </b-form-checkbox>

                  <b-form-group
                    v-if="
                      selectTeamProjectTypes[index] &&
                        selectTeamProjectTypes[index].dtype_id
                    "
                    :key="'type-select1-u-' + type.id"
                    label="Stade : "
                    class="stade-sous-stade-flex"
                  >
                    <span class="project-type-sub-tree"></span>
                    <Multiselect
                      v-model="selectTeamProjectTypes[index].cstage_id"
                      @select="handleStageUpdateChange($event, type, index)"
                      label="name"
                      track-by="id"
                      :showLabels="false"
                      :closeOnSelect="true"
                      open-direction="bottom"
                      :allow-empty="true"
                      :options="type.stages"
                      :searchable="true"
                      :internal-search="true"
                      :max-height="600"
                      placeholder="Rechercher stade"
                      required
                    >
                      <span slot="noResult">Aucun stade trouvé.</span>
                      <span slot="noOptions">Aucun stade trouvé.</span>
                    </Multiselect>
                  </b-form-group>
                  <b-form-group
                    v-if="
                      selectTeamProjectTypes[index] &&
                        selectTeamProjectTypes[index].dtype_id &&
                        selectTeamProjectTypes[index].cstage_id &&
                        selectTeamProjectTypes[index].subStages
                    "
                    :key="'type-select2-u-' + type.id"
                    label="Sous-stade : "
                    class="stade-sous-stade-flex"
                  >
                    <span class="project-type-sub-tree child"></span>
                    <Multiselect
                      v-model="selectTeamProjectTypes[index].csubstage_id"
                      label="name"
                      track-by="id"
                      :showLabels="false"
                      :closeOnSelect="true"
                      open-direction="bottom"
                      :allow-empty="true"
                      :options="selectTeamProjectTypes[index].subStages"
                      :searchable="true"
                      :internal-search="true"
                      :max-height="600"
                      placeholder="Rechercher sous-stade"
                      required
                    >
                      <span slot="noResult">Aucun sous-stade trouvé.</span>
                      <span slot="noOptions">Aucun sous-stade trouvé.</span>
                    </Multiselect>
                  </b-form-group>
                </template>
              </b-form-group>
              <!-- 3 COMMISSIONS  -->
              <b-form-group
                label="Commission 101 *"
                label-for="commission101-input"
              >
                <b-form-input
                  type="number"
                  id="commission101-input"
                  v-model="selectTeam.coeff_101"
                  :required="
                    selectTeam.type == 'regie' ||
                      selectTeam.type == 'installateur'
                  "
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Commission 102 *"
                label-for="commission102-input"
              >
                <b-form-input
                  type="number"
                  id="commission102-input"
                  v-model="selectTeam.coeff_102"
                  :required="
                    selectTeam.type == 'regie' ||
                      selectTeam.type == 'installateur'
                  "
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Commission 103 *"
                label-for="commission103-input"
              >
                <b-form-input
                  type="number"
                  id="commission103-input"
                  v-model="selectTeam.coeff_103"
                  :required="
                    selectTeam.type == 'regie' ||
                      selectTeam.type == 'installateur'
                  "
                ></b-form-input>
              </b-form-group>
            </div>
          </b-col>
          <b-col class="col2">
            <div v-if="loading" class="three-dots-loading">
              Chargement en cours
            </div>
            <b-form-group
              label=" Membres de l'équipe "
              v-if="selectTeam.type == 'ge' && !loading"
            ></b-form-group>
            <div
              :class="{
                defilement:
                  selectTeam.type != 'regie' ||
                  selectTeam.type != 'installateur',
                'defilement-regie':
                  selectTeam.type == 'regie' ||
                  selectTeam.type == 'installateur'
              }"
            >
              <div
                v-if="
                  selectTeam &&
                    membresPossibleUpdate &&
                    membresPossibleUpdate.length &&
                    selectTeam.type == 'ge'
                "
              >
                <b-form-checkbox
                  v-for="item in membresPossibleUpdate"
                  :key="item.id"
                  v-model="selectTeam.selected[item.id]"
                >
                  <div
                    :class="{
                      'icon-exclamation': item.pipe_tech_deleted === 1
                    }"
                    :title="
                      item.pipe_tech_deleted === 1
                        ? 'Technicien supprimé de pipedrive'
                        : ''
                    "
                  >
                    {{ item.first_name }} {{ item.last_name }}
                  </div>
                  <div>
                    <strong class="type">
                      ({{ item.type | typeFormat }})
                    </strong>
                  </div>
                </b-form-checkbox>
              </div>
              <div
                v-else-if="!loading && selectTeam.type == 'ge'"
                class="aucunMembres"
              >
                Aucun Membres
              </div>
              <b-form-group
                v-if="
                  (selectTeam.type == 'filiale' ||
                    selectTeam.type == 'regie' ||
                    selectTeam.type == 'installateur') &&
                    !loading
                "
                label="Liste admins secondaires"
              >
                <div
                  v-if="
                    selectTeam &&
                      listsAdminsUpdateComputed &&
                      listsAdminsUpdateComputed.length
                  "
                >
                  <b-form-checkbox
                    v-for="item in listsAdminsUpdateComputed"
                    :key="item.id"
                    v-model="selectTeam.selected[item.id]"
                  >
                    <div>{{ item.first_name }} {{ item.last_name }}</div>
                    <div>
                      <strong class="type">
                        ({{ item.type | typeFormat }})
                      </strong>
                    </div>
                  </b-form-checkbox>
                </div>
                <div v-else class="aucunMembres">Aucun Admin</div>
              </b-form-group>
              <b-form-group
                v-if="
                  (selectTeam.type == 'filiale' ||
                    selectTeam.type == 'regie' ||
                    selectTeam.type == 'installateur') &&
                    !loading
                "
                label=" Membres de l'équipe "
              >
                <div
                  v-if="
                    selectTeam &&
                      membersComputedUpdate &&
                      membersComputedUpdate.length
                  "
                >
                  <b-form-checkbox
                    v-for="item in membersComputedUpdate"
                    :key="item.id"
                    v-model="selectTeam.selected[item.id]"
                  >
                    <div
                      :class="{
                        'icon-exclamation': item.pipe_tech_deleted === 1
                      }"
                      :title="
                        item.pipe_tech_deleted === 1
                          ? 'Technicien supprimé de pipedrive'
                          : ''
                      "
                    >
                      {{ item.first_name }} {{ item.last_name }}
                    </div>
                    <div>
                      <strong class="type">
                        ({{ item.type | typeFormat }})
                      </strong>
                    </div>
                  </b-form-checkbox>
                </div>
                <div v-else-if="!loading" class="aucunMembres">
                  Aucun Membres
                </div>
              </b-form-group>
            </div>
          </b-col>
        </b-row>
        <!-- Update Team Modal: error message -->
        <div class="message">
          <div v-if="loading" class="three-dots-loading">
            Chargement en cours
          </div>
          <div v-if="error" class="error">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div>
        </div>
        <!-- Update Team Modal: buttons -->
        <div class="form-actions">
          <b-button
            @click="hideModal('ModalUpdateTeam')"
            variant="outline-secondary"
          >
            Annuler
          </b-button>
          <b-button variant="success" type="submit">Enregistrer</b-button>
        </div>
      </form>
    </b-modal>
    <!-- END Update Team Modal -->

    <!-- Delete Team Modal -->
    <b-modal
      no-close-on-backdrop
      ref="ModalDelete"
      id="ModalDelete"
      title=" Supprimer une équipe"
      :hide-footer="true"
    >
      <div
        v-if="
          equipeToDelete &&
            equipeToDelete.type == 'filiale' &&
            equipeToDelete.depots &&
            equipeToDelete.depots.data &&
            equipeToDelete.depots.data.length
        "
      >
        <p class="parg">
          Êtes-vous certain de vouloir supprimer la filiale
          <strong> {{ equipeToDelete ? equipeToDelete.name : '' }}</strong>
          ?
        </p>
        <p>
          <span class="label"> Attention :</span> sa suppression va engendrer la
          suppression de ses agences :
          <strong>
            {{
              equipeToDelete ? equipeToDelete.depots.data[0].name : ''
            }}</strong
          >
        </p>
      </div>
      <div v-else>
        <p>
          Êtes-vous certain de vouloir supprimer l'équipe
          <strong> {{ equipeToDelete ? equipeToDelete.name : '' }}</strong> ?
        </p>
      </div>
      <div class="form-actions">
        <b-button @click="hideModal('ModalDelete')" variant="outline-secondary"
          >Annuler</b-button
        >
        <b-button variant="success" @click="handleModalValider"
          >Valider</b-button
        >
      </div>
      <div class="message">
        <div v-if="loading" class="three-dots-loading">Chargement en cours</div>
        <div v-if="error" class="error">
          <ul v-if="Array.isArray(error)">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ error }}</span>
        </div>
      </div>
    </b-modal>
    <!-- END Delete Team Modal -->
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Teams',
  components: {
    Multiselect
  },
  data() {
    return {
      teamToAdd: {
        type: null,
        name: null,
        source_id: null,
        see_form_fast: 0,
        stock: 0,
        set_lead: 0,
        acces_documents: 0,
        // pipe_user_id: null,
        coeff_101: null,
        coeff_102: null,
        coeff_103: null,
        manager_id: null,
        manager_id_regie: null,
        comm_sed_id: null,
        projectTypes: [],
        members: [],
        selected: [],
        selectedTechnicians: [],
        selectedGe: [],
        image: null,
        imageData: '',
        imageDataUpdate: '',
        subcategories: []
      },
      type: null,
      selectSource: null,
      selectTeam: null,
      selectTeamProjectTypes: [],
      selectedcheck: null,
      equipeToDelete: null,
      loading: false,
      error: null,
      initLoading: true,
      membresPossibleUpdate: [],
      managerselected: [],
      managerselectedRegie: [],
      selectedSubcategories: [],
      full_name: null,
      sortDesc: false,
      sort_by: 'full_name',
      sort_by_desc: 'full_name'
    }
  },
  methods: {
    ...mapActions([
      'updateTeams',
      'getAdminsTeams',
      'getAdminsTeamsGlobal',
      'deleteTeams',
      'createTeam',
      'getTeams',
      'getStages',
      'getSubStages',
      'getPipeline',
      'getRegies',
      'getOwners',
      'getcommercials',
      'gettechnicians',
      'getteamsTypes',
      'getMembresPossibleUpdate',
      'getMembresGe',
      'fetchAdminRegie',
      'fetchRegieUpdate',
      'fetchProjetsTypes',
      'fetchPipelinesPipelines',
      'fetchUsersCommercialSedentaire',
      'fetchListSousCategoryTeams'
    ]),
    previewImage: function(event) {
      var input = event.target
      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = () => {
          this.teamToAdd.imageData = input.files[0]
        }
        reader.readAsDataURL(input.files[0])
      }
    },
    previewImageUpdate: function(event) {
      var input = event.target
      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = () => {
          this.selectTeam.imageDataUpdate = input.files[0]
        }
        reader.readAsDataURL(input.files[0])
      }
    },
    hideModal(ref) {
      this.$refs[ref].hide()
      this.resetModal()
    },
    async handleModalValider() {
      this.loading = true
      this.error = null
      const response = await this.deleteTeams(this.equipeToDelete.id)
      if (response.success) {
        this.loading = false
        this.getRegies()
        this.getAdminsTeamsGlobal({
          exclude: [
            'team',
            'visibleTeams',
            'technicianPipe',
            'commercialItePipe',
            'userPipe'
          ]
        })
        this.getAdminsTeams()
        this.gettechnicians()
        this.getcommercials()
        this.getMembresGe()
        this.hideModal('ModalDelete')
      } else {
        this.loading = false
        this.error = response.error
      }
    },
    changeType(type) {
      this.type = type.id
    },
    handleDeleteClick(Equipe) {
      this.equipeToDelete = Equipe
      this.$refs['ModalDelete'].show()
    },
    handleStageChange(value, type, index) {
      this.teamToAdd.projectTypes[index].subStages = null
      this.teamToAdd.projectTypes[index].csubstage_id = null
      for (let i = 0; i < type.stages.length; i++) {
        const element = type.stages[i]
        if (value.id == element.id) {
          this.teamToAdd.projectTypes[index].subStages = element.subStages
        }
      }
    },
    async handleUpdateClick(team) {
      if (team.pipeline) {
        this.selectTeam = { ...team }
      } else {
        this.selectTeam = {
          ...team,
          pipeline: { data: { id: null } },
          stage: { data: { id: null } },
          substage: { data: { id: null } }
        }
      }

      this.$refs['ModalUpdateTeam'].show()
      if (
        this.selectTeam.subCategory &&
        this.selectTeam.subCategory &&
        this.selectTeam.subCategory.data.length
      ) {
        this.selectedSubcategories = this.getSubCategory.filter(item => {
          for (
            let index = 0;
            index < this.selectTeam.subCategory.data.length;
            index++
          ) {
            if (this.selectTeam.subCategory.data[index].id == item.id) {
              return item
            }
          }
        })
      }
      if (
        this.selectTeam.type == 'regie' ||
        this.selectTeam.type == 'installateur'
      ) {
        // this.selectTeam.pipe_user_id = this.selectTeam.proprietaire.data.id
        this.selectTeamProjectTypes = []
        let visibleProjectTypes = []
        if (
          this.selectTeam.visibleDtypes &&
          this.selectTeam.visibleDtypes.data
        ) {
          visibleProjectTypes = this.selectTeam.visibleDtypes.data
        }
        for (let index = 0; index < this.computedProjectTypes.length; index++) {
          const visiblePType = visibleProjectTypes.find(
            item =>
              item.dtype_id == this.computedProjectTypes[index].id &&
              this.computedProjectTypes[index].stages &&
              this.computedProjectTypes[index].stages.length
          )
          const ptype = {
            dtype_id: null,
            cstage_id: null,
            csubstage_id: null,
            subStages: null
          }
          if (visiblePType) {
            ptype.dtype_id = visiblePType.dtype_id
            ptype.cstage_id = {
              id: visiblePType.cstage_id,
              name: visiblePType.cstage_name
            }
            ptype.csubstage_id = {
              id: visiblePType.csubstage_id,
              name: visiblePType.csubstage_name
            }
            const selectedStage = this.computedProjectTypes[index].stages.find(
              item => item.id == ptype.cstage_id.id
            )
            if (selectedStage) {
              ptype.subStages = selectedStage.subStages
            }
          }
          this.selectTeamProjectTypes.push(ptype)
        }
        this.fetchAdminRegie(this.selectTeam.id)
        this.managerselectedRegie = this.selectTeam.manager.data
        await this.fetchRegieUpdate(this.selectTeam.id)

        for (let index = 0; index < this.regieUpdate.length; index++) {
          const source = this.regieUpdate[index]
          if (this.selectTeam && this.selectTeam.source_id == source.id) {
            this.selectSource = source.internal
            if (this.selectSource == 0) {
              this.selectTeam.comm_sed_id = source.commercial_sedentaire.data.id
            }
            this.selectTeam.source_id = {
              name_internal: source.name_internal,
              id: source.id
            }
          }
        }
      }
      // Team Members
      if (this.selectTeam) {
        this.selectTeam.selected = []
        this.selectTeam.members.data.forEach(member => {
          if (member.type != 'user.final') {
            this.selectTeam.selected[member.id] = true
          }
        })
      }

      if (
        this.selectTeam &&
        (this.selectTeam.type == 'filiale' || this.selectTeam.type == 'ge')
      ) {
        this.getAdminsTeamsGlobal({
          exclude: [
            'team',
            'visibleTeams',
            'technicianPipe',
            'commercialItePipe',
            'userPipe'
          ]
        })
        this.managerselected = this.selectTeam.manager.data
      }
      this.loading = true
      this.membresPossibleUpdate = []
      if (this.selectTeam) {
        this.membresPossibleUpdate = await this.getMembresPossibleUpdate({
          id: this.selectTeam.id,
          per_page: 50,
          exclude: [
            'team',
            'visibleTeams',
            'technicianPipe',
            'commercialItePipe',
            'userPipe'
          ]
        })
      }
      this.loading = false
    },
    resetModal() {
      this.teamToAdd = {
        type: '',
        name: null,
        source_id: null,
        see_form_fast: 0,
        stock: 0,
        set_lead: 0,
        acces_documents: 0,
        // pipe_user_id: null,
        coeff_101: null,
        coeff_102: null,
        coeff_103: null,
        manager_id: null,
        manager_id_regie: null,
        projectTypes: [],
        members: [],
        selected: [],
        selectedTechnicians: [],
        selectedGe: [],
        image: null,
        imageData: '',
        imageDataUpdate: '',
        comm_sed_id: null,
        subcategories: []
      }
      this.type = null
      this.selectSource = null
      this.selectTeam = null
      this.selectTeamProjectTypes = []
      this.selectedcheck = null
      this.equipeToDelete = null
      this.loading = false
      this.error = null
      this.membresPossibleUpdate = []
      this.managerselected = []
      this.managerselectedRegie = []
      this.selectedSubcategories = []
      for (let index = 0; index < this.getProjectsTypes.length; index++) {
        this.teamToAdd.projectTypes.push({
          dtype_id: null,
          cstage_id: null,
          csubstage_id: null,
          subStages: null
        })
      }
    },
    handleStageUpdateChange(value, type, index) {
      this.selectTeamProjectTypes[index].subStages = null
      this.selectTeamProjectTypes[index].csubstage_id = null
      for (let i = 0; i < type.stages.length; i++) {
        const element = type.stages[i]
        if (value.id == element.id) {
          this.selectTeamProjectTypes[index].subStages = element.subStages
        }
      }
    },
    validateFilter(teams, update) {
      let type = null
      if (teams.type.id) {
        type = teams.type.id
      } else {
        type = teams.type
      }
      const validate = {
        status: 'success',
        errors: []
      }
      if (type == 'regie' || type == 'installateur') {
        const visibleProjectTypes = teams.projectTypes.filter(item => {
          if (item.dtype_id) {
            return true
          }
          return false
        })
        if (visibleProjectTypes && visibleProjectTypes.length == 0) {
          validate.status = 'error'
          validate.errors.push('Il faut choisir un type de projet')
        } else {
          for (let index = 0; index < visibleProjectTypes.length; index++) {
            const type = visibleProjectTypes[index]
            if (
              // type.dtype_id != 112 &&
              !type.cstage_id ||
              !type.csubstage_id
            ) {
              let type_name = this.computedProjectTypes.find(
                item => item.id == type.dtype_id
              )
              validate.status = 'error'
              validate.errors.push(
                // 'les champs des types des projets sont obligatoires'
                'merci de spécifier un pipeline principal pour le type de projet : ' +
                  type_name.name
              )
            }
          }
        }

        if (update) {
          if (!teams.selectManagerRegie) {
            validate.status = 'error'
            validate.errors.push("Le champ Chef d'équipe est obligatoire")
          }
        } else {
          if (!teams.manager_id_regie) {
            validate.status = 'error'
            validate.errors.push("Le champ Chef d'équipe est obligatoire")
          }
        }
        if (!teams.source_id) {
          validate.status = 'error'
          validate.errors.push('Le champ source est obligatoire')
        }
      } else {
        if (update) {
          if (!teams.selectManager) {
            validate.status = 'error'
            validate.errors.push("Le champ Chef d'équipe est obligatoire")
          }
        } else {
          if (!teams.manager_id) {
            validate.status = 'error'
            validate.errors.push("Le champ Chef d'équipe est obligatoire")
          }
        }
      }
      return validate
    },
    async handleSubmit() {
      const validate = this.validateFilter(this.teamToAdd)
      if (validate.status === 'error') {
        this.error = validate.errors
        return
      }
      if (this.type == 'regie' || this.type == 'installateur') {
        const members = []
        this.teamToAdd.selected.forEach((element, key) => {
          if (element) {
            members.push(key)
          }
        })
        this.teamToAdd.members = members
      } else if (this.type == 'filiale') {
        // Team members (filiale)
        const members = []
        this.teamToAdd.selectedTechnicians.forEach((element, key) => {
          if (element) {
            members.push(key)
          }
        })
        this.teamToAdd.members = members
      } else if (this.type == 'ge') {
        // Team members (ge)
        const members = []
        for (let index = 0; index < this.teamToAdd.selectedGe.length; index++) {
          if (this.teamToAdd.selectedGe[index]) {
            members.push(this.membresGe[index].id)
          }
        }
        this.teamToAdd.members = members
      }
      /**
       * formatting request
       */
      const formData = new FormData()
      // Team type
      formData.append('type', this.type)
      // Team name
      formData.append('name', this.teamToAdd.name)
      // Team manager (filiale/ge)
      if (this.type == 'filiale' || this.type == 'ge') {
        formData.append('manager_id', this.teamToAdd.manager_id.id)
        // Team Sous Category
        if (
          this.teamToAdd.subcategories &&
          this.teamToAdd.subcategories.length
        ) {
          for (
            let index = 0;
            index < this.teamToAdd.subcategories.length;
            index++
          ) {
            formData.append(
              'subcategories[' + index + ']',
              this.teamToAdd.subcategories[index].id
            )
          }
        } else {
          formData.append('subcategories', '')
        }
        // Team stock (regie)
        formData.append('stock', this.teamToAdd.stock)
      }
      // Team members
      if (this.teamToAdd.members) {
        for (let index = 0; index < this.teamToAdd.members.length; index++) {
          formData.append(
            'members[' + index + ']',
            this.teamToAdd.members[index]
          )
        }
      }

      // Team logo (filiale)
      if (this.type == 'filiale') {
        if (this.teamToAdd.imageData) {
          formData.append('image', this.teamToAdd.imageData)
        }
      }

      if (this.type == 'regie' || this.type == 'installateur') {
        // Team manager (regie)
        formData.append('manager_id', this.teamToAdd.manager_id_regie.id)
        // Team Sous Category
        if (
          this.teamToAdd.subcategories &&
          this.teamToAdd.subcategories.length
        ) {
          for (
            let index = 0;
            index < this.teamToAdd.subcategories.length;
            index++
          ) {
            formData.append(
              'subcategories[' + index + ']',
              this.teamToAdd.subcategories[index].id
            )
          }
        } else {
          formData.append('subcategories', '')
        }
        // Team source (regie)
        formData.append('source_id', this.teamToAdd.source_id.id)
        // Team form (regie)
        formData.append('see_form_fast', this.teamToAdd.see_form_fast)
        // Team stock (regie)
        formData.append('stock', this.teamToAdd.stock)
        //Team affecter lead
        formData.append('set_lead', this.teamToAdd.set_lead)
        // Team affecter acces document (regie)
        if (this.type == 'regie') {
          formData.append('acces_documents', this.teamToAdd.acces_documents)
        }
        // Team owner (regie)
        // formData.append('owner_id', this.teamToAdd.pipe_user_id)
        // Team coeff (regie)
        formData.append('coeff_101', this.teamToAdd.coeff_101)
        formData.append('coeff_102', this.teamToAdd.coeff_102)
        formData.append('coeff_103', this.teamToAdd.coeff_103)
        // Team visible projectTypes (regie)
        const visibleProjectTypes = this.teamToAdd.projectTypes.filter(item => {
          if (item.dtype_id) {
            return true
          }
          return false
        })
        for (let index = 0; index < visibleProjectTypes.length; index++) {
          formData.append(
            'dtypes[' + index + '][dtype_id]',
            visibleProjectTypes[index].dtype_id
          )
          if (
            visibleProjectTypes[index].cstage_id &&
            visibleProjectTypes[index].cstage_id.id
          ) {
            formData.append(
              'dtypes[' + index + '][cstage_id]',
              visibleProjectTypes[index].cstage_id.id
            )
          } else {
            formData.append('dtypes[' + index + '][cstage_id]', '')
          }
          if (
            visibleProjectTypes[index].csubstage_id &&
            visibleProjectTypes[index].csubstage_id.id
          ) {
            formData.append(
              'dtypes[' + index + '][csubstage_id]',
              visibleProjectTypes[index].csubstage_id.id
            )
          } else {
            formData.append('dtypes[' + index + '][csubstage_id]', '')
          }
        }
      }
      this.loading = true
      this.error = null
      // Create team
      const response = await this.createTeam(formData)
      if (response.success) {
        this.loading = false
        this.getAdminsTeamsGlobal({
          exclude: [
            'team',
            'visibleTeams',
            'technicianPipe',
            'commercialItePipe',
            'userPipe'
          ]
        })
        this.getAdminsTeams()
        this.hideModal('addTeamsModal')
        this.getRegies()
        this.getOwners({
          exclude: ['visibilityFilter', 'team', 'visibleTeams', 'userPipe']
        })
        this.gettechnicians()
        this.getcommercials()
        this.getMembresGe()
      } else {
        this.loading = false
        this.error = response.error
      }
    },
    async handleSubmitUpdate() {
      if (
        this.selectTeam &&
        (this.selectTeam.type == 'regie' ||
          this.selectTeam.type == 'installateur')
      ) {
        this.selectTeam.projectTypes = this.selectTeamProjectTypes
        this.selectTeam.selectManagerRegie = this.managerselectedRegie
      }
      this.selectTeam.selectManager = this.managerselected
      this.selectTeam.subcategories = this.selectedSubcategories
      const validate = this.validateFilter(this.selectTeam, 'update')
      if (validate.status === 'error') {
        this.error = validate.errors
        return
      }
      this.loading = true
      this.error = null
      const response = await this.updateTeams(this.selectTeam)
      if (response.success) {
        this.loading = false
        this.getRegies()
        this.fetchRegieUpdate(this.selectTeam.id)
        this.getAdminsTeamsGlobal({
          exclude: [
            'team',
            'visibleTeams',
            'technicianPipe',
            'commercialItePipe',
            'userPipe'
          ]
        })
        this.getAdminsTeams()
        this.hideModal('ModalUpdateTeam')
        this.getOwners({
          exclude: ['visibilityFilter', 'team', 'visibleTeams', 'userPipe']
        })
        this.gettechnicians()
        this.getcommercials()
        this.getMembresGe()
      } else {
        this.loading = false
        this.error = response.error
      }
    },
    selectedSource(source) {
      for (let index = 0; index < this.computedRegies.length; index++) {
        const source_id = this.computedRegies[index]
        if (source_id.id == source.id) {
          this.teamToAdd.comm_sed_id = source_id.comm_sed_id
          this.selectSource = source_id.internal
        }
      }
    },
    selectedSourceUpdate(sourceUpdate) {
      this.selectTeam.comm_sed_id = null
      this.selectSource = null
      for (let index = 0; index < this.regieUpdate.length; index++) {
        const source = this.regieUpdate[index]
        if (sourceUpdate.id == source.id) {
          this.selectTeam.comm_sed_id = source.comm_sed_id
          this.selectSource = source.internal
        }
      }
    },
    filter() {
      if (!this.sortDesc) {
        this.getTeams({
          exclude: [
            'visibleToUsers',
            'visibleToUsers',
            'depots',
            'members.technicianPipe',
            'members.depot',
            'members.userPipe',
            'members.commercialItePipe',
            'members.visibleTeams'
          ],
          sort_by: this.sort_by,
          name: this.full_name
        })
      } else {
        this.getTeams({
          exclude: [
            'visibleToUsers',
            'visibleToUsers',
            'depots',
            'members.technicianPipe',
            'members.depot',
            'members.userPipe',
            'members.commercialItePipe',
            'members.visibleTeams'
          ],
          sort_by_desc: this.sort_by_desc,
          name: this.full_name
        })
      }
    },
    sortBy() {
      this.sortDesc = !this.sortDesc
      if (!this.sortDesc) {
        this.getTeams({
          exclude: [
            'visibleToUsers',
            'visibleToUsers',
            'depots',
            'members.technicianPipe',
            'members.depot',
            'members.userPipe',
            'members.commercialItePipe',
            'members.visibleTeams'
          ],
          sort_by: this.sort_by,
          name: this.full_name
        })
      } else {
        this.getTeams({
          exclude: [
            'visibleToUsers',
            'visibleToUsers',
            'depots',
            'members.technicianPipe',
            'members.depot',
            'members.userPipe',
            'members.commercialItePipe',
            'members.visibleTeams'
          ],
          sort_by_desc: this.sort_by_desc,
          name: this.full_name
        })
      }
    }
  },

  computed: {
    ...mapGetters([
      'teams',
      'admins',
      'adminsGlobal',
      'regies',
      'owners',
      'stages',
      'subStages',
      'commercials',
      'technicians',
      'teamsTypes',
      'membresGe',
      'adminUpdate',
      'regieUpdate',
      'getProjectsTypes',
      'getPipelinesPipelines',
      'getProjectsUsersCommercialsSedentaires',
      'getTeamsLoading',
      'getSubCategory'
    ]),
    adminsComputed: function() {
      if (this.selectTeam) {
        const admins = [...this.adminsGlobal]
        const manager = this.selectTeam.manager.data
        manager.name = manager.first_name + ' ' + manager.last_name
        admins.push(this.selectTeam.manager.data)
        return admins
      }
      return []
    },
    computedRegies: function() {
      return this.regies.filter(
        r =>
          !r.selected ||
          (this.selectTeam && this.selectTeam.regie.data.id == r.id)
      )
    },
    listsAdminsComputed: function() {
      return this.technicians.filter(
        listsAdmins => listsAdmins.type == 'user.admin'
      )
    },
    membersComputed: function() {
      return this.technicians.filter(members => members.type !== 'user.admin')
    },
    listsAdminsUpdateComputed: function() {
      return this.membresPossibleUpdate.filter(
        listsAdminsUpdate => listsAdminsUpdate.type == 'user.admin'
      )
    },
    membersComputedUpdate: function() {
      return this.membresPossibleUpdate.filter(
        membersUpdate => membersUpdate.type !== 'user.admin'
      )
    },
    membersRegieComputed: function() {
      return this.commercials.filter(members => members.type !== 'user.admin')
    },
    listsAdminsRegieComputed: function() {
      return this.commercials.filter(
        listsAdmins => listsAdmins.type == 'user.admin'
      )
    },
    computedProjectTypes: function() {
      const types = []
      this.getProjectsTypes.forEach(t => {
        const type = {
          id: t.id,
          name: t.name,
          stages: [],
          is_principal: 0,
          pipeline_name: ''
        }
        for (
          let index = 0;
          index < this.getPipelinesPipelines.length;
          index++
        ) {
          const pipeline = this.getPipelinesPipelines[index]
          if (pipeline.type.id == type.id && pipeline.is_principal == 1) {
            type.stages = pipeline.stages
            type.is_principal = pipeline.is_principal
            type.pipeline_name = pipeline.name
            break
          }
        }
        types.push(type)
      })
      return types
    },
    computedadminUpdate: function() {
      return this.adminUpdate.map(admin => {
        return { full_name: admin.name, id: admin.id }
      })
    }
  },
  async mounted() {
    await this.getTeams({
      exclude: [
        'visibleToUsers',
        'visibleToUsers',
        'depots',
        'members.technicianPipe',
        'members.depot',
        'members.userPipe',
        'members.commercialItePipe',
        'members.visibleTeams'
      ],
      sort_by: this.sort_by
    })
    this.fetchProjetsTypes({ exclude: ['fields'] })
    this.fetchPipelinesPipelines()
    this.getRegies()
    this.fetchListSousCategoryTeams()
    this.getAdminsTeamsGlobal({
      exclude: [
        'team',
        'visibleTeams',
        'technicianPipe',
        'commercialItePipe',
        'userPipe'
      ]
    })
    this.getAdminsTeams()
    this.getOwners({
      exclude: ['visibilityFilter', 'team', 'visibleTeams', 'userPipe']
    })
    this.getcommercials()
    this.gettechnicians()
    this.getteamsTypes()
    this.getMembresGe()
    this.fetchUsersCommercialSedentaire()
    this.initLoading = false
    this.teamsTypes
  },
  filters: {
    typeFormat: value => {
      switch (value) {
        case 'user.admin':
          return 'Utilisateur Admin'
        case 'user.agent':
          return 'Agent'
        case 'user.resp.plan':
          return 'Responsable Planning'
        case 'user.supervisor':
          return 'Régie Superviseur'
        case 'user.commercial':
          return 'Régie Commercial'
        case 'user.final':
          return 'Régie Utilisateur Final'
        case 'user.technicien':
          return 'Filiale Poseur'
        case 'user.administration':
          return 'CERTIGAIA Administration'
        case 'user.client':
          return 'Client'
        case 'user.commercial.ite':
          return 'AMO'
        case 'user.sub-super-admin':
          return 'Sous Super Admin'
        case 'user.ingenieur':
          return 'Ingénieur'
        case 'user.visiteur.tech':
          return 'Visiteur Technique'
        case 'user.tech.travaux':
          return 'Technicien Travaux'
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.teams-setting {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .button {
      display: flex;
      .searchIcon {
        border-radius: unset;
        box-shadow: unset;
        cursor: pointer;
        border: 1px solid #6c757d59;
        &:focus {
          box-shadow: unset;
        }
        &.padd-btn {
          padding: 0px 9px;
        }
      }
    }
  }
  .input-search-teams {
    margin-top: 15px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    width: 100%;
    .block-search {
      cursor: pointer;
      .search {
        border: 1px solid #dadada;
        outline: none;
        font-size: 16px;
        height: 40px;
        background: #fff;
        padding-left: 10px;
      }
      .searchIcon {
        padding: 10px;
        border: 1px solid #d6d8db;
        font-size: 16px;
        background: #d6d8da17;
        cursor: pointer;
      }
    }
    .init-loading {
      margin-left: 50px;
    }
  }
  .list-group {
    .aucun-equipe {
      color: #007bff;
      text-align: center;
    }
    .list-group-item {
      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .gras {
          margin-left: 12px;
        }
        .name {
          font-weight: bold;
          text-transform: capitalize;
        }
        .type {
          color: #317ae2;
          font-weight: normal;
          font-size: 12px;
        }
        .right {
          display: flex;
          justify-content: flex-end;

          .actions {
            display: block;
            margin-right: 20px;
            button {
              margin: 0 2px;
            }
          }
          .icon {
            display: flex;
            flex-direction: column-reverse;
            margin-right: 16px;
          }
        }
      }

      &.collapsed {
        .icon {
          flex-direction: column !important;
        }
      }
    }
  }
  .mt-2 {
    ul {
      margin-top: 0;
      margin-bottom: 0rem;
    }
    p {
      margin-top: 5px;
      margin-bottom: 0.5rem;
    }
    .type {
      font-weight: normal;
      text-transform: capitalize;
      font-size: 12px;
      color: #317ae2;
    }
    .message {
      padding-top: 5px;
      font-size: 14px;
      color: #317ae2;
      margin-left: 20px;
    }
    .icon-exclamation {
      text-decoration: line-through;
      text-decoration-color: #e73e01;
    }
    .content-members {
      padding-top: 8px;
    }
  }
  @media screen and (max-width: 400px) {
    .input-search-teams {
      .block-search {
        input {
          width: 80%;
        }
      }
    }
  }
}
</style>

<style lang="scss">
#ModalDelete {
  strong {
    text-transform: capitalize;
  }
  .parg {
    margin-top: 0;
    margin-bottom: 0.3rem;
  }
  .label {
    color: #e73e01;
  }
  .message {
    .error {
      padding: 25px;
      color: #e73e01;
    }
  }
}

#addTeamsModal,
#ModalUpdateTeam {
  .modal-content {
    width: 115%;
  }
  .pipe-principal {
    font-size: 11px;
    min-width: max-content;
    .color {
      font-weight: normal;
      text-transform: capitalize;
      font-size: 10px;
      color: #317ae2;
    }
  }
  .stade-sous-stade-flex {
    position: relative;
    margin-left: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
    .project-type-sub-tree {
      border-bottom: 1px solid #08a742;
      border-left: 1px solid #08a742;
      position: absolute;
      width: 18px;
      height: 81px;
      top: -59px;
      left: -18px;
      background: #fff;
      z-index: 2;
      &.child {
        width: 10px;
        height: 100px;
        top: -78px;
        left: -10px;
        z-index: 1;
      }
    }
    label {
      font-size: 12px;
      font-weight: bold;
    }
  }
  .titre {
    padding-top: 20px;
  }
  .type {
    font-weight: normal;
    text-transform: capitalize;
    font-size: 12px;
    color: #317ae2;
  }
  .mrgswitch {
    margin-left: -18px;
  }
  .check-switch {
    align-items: center;
    display: flex;
    margin-bottom: 12px;
    .switch {
      margin-left: 20px;
    }
  }
  .icon-exclamation {
    text-decoration: line-through;
    text-decoration-color: #e73e01;
  }
  .aucunMembres {
    color: #317ae2;
    margin-left: 35px;
  }
  .message {
    .error {
      padding: 25px;
      color: #ff5722;
    }
    .modifier {
      color: forestgreen;
    }
  }
  .form-actions {
    padding-top: 20px;
  }
  .defilement {
    // position: relative;
    max-height: 500px;
    overflow: auto;
  }
  .defilement-regie {
    max-height: 500px;
    overflow: auto;
  }
  .image-preview {
    position: relative;
    display: block;
    max-width: 200px;
  }
  .form-control,
  .custom-select:disabled {
    border: 1px solid #e8e8e8;
  }
}
</style>
